import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { ModalNewBookProps } from "./ModalNewBook.types";
import { api } from "../../service/api";
import { Input } from "../form/input";

type ModalFormData = {
  title: string;
};

const modalFormSchema = yup.object().shape({
  title: yup.string().required("Informe o campo obrigatório."),
});

export function ModalNewBook({
  is_open,
  onClose,
  onCloseSend,
  ...rest
}: ModalNewBookProps) {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(modalFormSchema) });

  const handleSend: SubmitHandler<ModalFormData> = async (value) => {
    try {
      const response = await api.post("biography/create/title", {
        title: value.title,
      });
      reset();
      onCloseSend();
      // navigate(`/biografia/chatboot/${response.data.id}`);
      window.location.href = `/biografia/chatboot/${response.data.id}`;
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar novo livro",
        description: "Não foi possível criar novo livro.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={() => {
          reset();
          onClose();
        }}
        isCentered
        closeOnOverlayClick={false}
        {...rest}
        size="md"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader
            textAlign="left"
            style={{
              color: "#181818",
              fontSize: 16,
              fontWeight: 600,
            }}
          ></ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={5}>
            <Flex
              flexDirection="column"
              as="form"
              onSubmit={handleSubmit(handleSend)}
            >
              <Input
                label="Sobre quem vamos falar?"
                placeholder="Olá! Vamos começar a criar a biografia."
                error={errors.title}
                {...register("title")}
              />
              <Button
                isLoading={isSubmitting}
                type="submit"
                bg="blue.400"
                color="white"
                style={{
                  borderRadius: 10,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                _hover={{
                  bg: "blue.400",
                }}
                mt="6"
                size="lg"
              >
                Começar
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
