import { useState } from "react";
import { api } from "../../../../service/api";
import { PageDataDTO } from "../../../../dtos/PageDataDTO";
import axios from "axios";

type Props = {
  id: string;
  package_id?: string;
};

export function useListPageData() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [dataList, setDataList] = useState<PageDataDTO | null>(
    {} as PageDataDTO
  );

  const fetchData = async ({ id, package_id = "" }: Props) => {
    try {
      setLoading(true);
      setDataList(null);
      setErrorMessage(null);
      setError(false);
      const response = await api.get(
        `book/page/show/${id}?package_id=${package_id}`
      );
      setDataList(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        let messsage = error.response?.data?.error;
        if (messsage) {
          setErrorMessage(messsage);
        }
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    dataList,
    loading,
    error,
    errorMessage,
    refetch: fetchData,
  };
}
