import { useEffect, useState } from "react";
import { api } from "../../../../../service/api";
import { Flex } from "@chakra-ui/react";
import { BoxLoading } from "../../../../../components/boxLoading";
import { useShowBookData } from "../../hooks/useShowBookData";
import { CoverItem } from "./components/coverItem";
import { useNavigate } from "react-router-dom";

const coversImg = [
  "cover/Ativo%201_page-0001.jpg",
  "cover/Ativo-2.jpg",
  "cover/Ativo-3.jpg",
  "cover/Ativo-4.jpg",
];

type Props = {
  id: string;
  pageType: string;
};
export function PageCover({ id, pageType }: Props) {
  const navigate = useNavigate();

  const { dataList, loading, refetch } = useShowBookData();
  const [loadingSend, setLoadingSend] = useState(false);

  async function deleteData(id: string) {
    try {
      await api.delete(`book/chapter/delete/${id}`);
    } catch (error) {}
  }

  async function sendCover(link: string) {
    try {
      setLoadingSend(true);
      if (dataList) {
        await deleteData(dataList.id);
      }
      const dataDocument = new FormData();
      dataDocument.append("biography_id", id);
      dataDocument.append("category_name", "cover");
      dataDocument.append("title", "Capa");
      dataDocument.append("path", link);
      await api.post("book/chapter/create", dataDocument);
      getData();
      navigate(`/biografia/meu-livro/${id}?type=title_ebook`);
    } catch (error) {
    } finally {
      setLoadingSend(false);
    }
  }

  function getData() {
    refetch({
      id,
      categoryName: "cover",
    });
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageType]);

  return (
    <Flex flexDirection="column" mt="5px" width="100%" position="relative">
      {(loading || loadingSend) && <BoxLoading />}

      {!loading && !loadingSend && (
        <Flex
          gap="20px"
          flexDirection={["column", "column", "row"]}
          margin={["auto", "auto", "initial"]}
        >
          {coversImg.map((item, _) => (
            <CoverItem
              isSelected={
                dataList && dataList.path
                  ? dataList.path.replace(
                      "https://cdn.cursodeinglesparabrasil.com.br/",
                      ""
                    ) === item
                  : false
              }
              bookImageIreview={item}
              onPress={() => {
                sendCover(item);
              }}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
}
