import dayjs from "dayjs";
import { OrderBookBiographyDTO } from "../dtos/BiographyDTO";

export const URL_WEB = "";

export function splitFullname(fullname: string) {
  const arrayName = fullname.split(" ");

  const firstName = arrayName[0];
  if (arrayName.length > 1) {
    const lastName = arrayName[arrayName.length - 1];
    return `${firstName} ${lastName}`;
  }

  return `${firstName}`;
}

export function formatedExtenseDate() {
  try {
    const date = new Date();
    var options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return date.toLocaleDateString("pt-BR", options);
  } catch (err) {
    return "";
  }
}

export function formatDecimal(value: number) {
  try {
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } catch (error) {
    return value;
  }
}

export function formatCurrency(price: number) {
  try {
    let newPrice = Math.round(price * 100);

    let value = String(newPrice);
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");

    if (price <= 0.99) return `0,${value}`;
    return value;
  } catch (error) {
    return price;
  }
}

export function clearPrice(price: string) {
  return price
    .replace("R$", "")
    .replace(" ", "")
    .replace(".", "")
    .replace(",", ".");
}

export function formatedMonthDay(date: string) {
  try {
    if (!date) return "";

    let newDate = date.split("-");

    let formatedDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;

    return String(formatedDate);
  } catch (error) {
    return "";
  }
}

export function formatedDateTimer(date: string, isNotTimer?: boolean) {
  try {
    if (!date) return "";
    let infoDate = date.split(" ");
    let newDate = infoDate[0].split("-");
    let timer = infoDate[1];

    let formatedDate = "";
    if (isNotTimer) {
      formatedDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
    } else {
      formatedDate = `${newDate[2]}/${newDate[1]}/${newDate[0]} ${timer}`;
    }

    return String(formatedDate);
  } catch (error) {
    return "";
  }
}

export function formatedDateTimerUs(date: string) {
  try {
    if (!date) return "";
    let infoDate = date.split("T");
    let newDate = infoDate[0].split("-");
    let timer = infoDate[1].split(":");

    let formatedDate = `${newDate[2]}/${newDate[1]}/${newDate[0]} ${timer[0]}:${timer[1]}`;

    return String(formatedDate);
  } catch (error) {
    return "";
  }
}

export function lmWord(text: string, limit: number) {
  return text.substring(0, limit) + (text.length > limit ? "..." : "");
}

export function differenceBetweenHours(
  startDate: string | null,
  endDate: string | null
) {
  if (!startDate || !endDate) return "00:00";

  let newStartDate = new Date(startDate);
  let newEndDate = new Date(endDate);
  let timer = (newEndDate.getTime() - newStartDate.getTime()) / 1000;

  let minutes = parseInt((timer / 60).toString(), 10);
  let seconds = parseInt((timer % 60).toString(), 10);
  let newMinutes = minutes < 10 ? "0" + minutes : minutes;
  let newSeconds = seconds < 10 ? "0" + seconds : seconds;
  return newMinutes + ":" + newSeconds;
}

export function getActualDateTimer() {
  var now = dayjs();
  return now.format();
}

export function sumQuantityCredits(orderBooks?: OrderBookBiographyDTO): string {
  return `${orderBooks?.used_pages}/${orderBooks?.total_pages}`;
}
