import "./styles.scss";
import { Flex } from "@chakra-ui/react";

export function TermsOfSse() {
  return (
    <Flex className="termsOfSee" style={{ flexDirection: "column" }}>
      <h1>Introdução</h1>
      <p>
        Bem-vindo à OCA BOOKS, nossa plataforma SaaS dedicada a ajudar pessoas a
        escrever, editar, publicar, imprimir e distribuir seus livros! Este
        documento estabelece os termos e condições sob os quais você pode
        acessar e utilizar nossos serviços.
      </p>
      <p>
        Ao utilizar nossa plataforma, você concorda em cumprir estes termos,
        elaborados para garantir uma experiência segura, eficiente e
        colaborativa.
      </p>
      <h2>1. Registro e Acesso à Plataforma</h2>
      <h3>1.1 Condições de Registro e Elegibilidade</h3>
      <ul>
        <li>
          Ter idade mínima de 18 anos ou contar com autorização de um
          responsável legal.
        </li>
        <li>
          Fornecer informações verdadeiras, completas e atualizadas durante o
          registro.
        </li>
      </ul>
      <p>
        A OCA BOOKS reserva-se o direito de suspender ou encerrar a conta do
        usuário caso sejam detectadas informações falsas ou atividades que
        violem estes Termos de Uso.
      </p>
      <h3>1.2 Segurança e Responsabilidade pelas Credenciais</h3>
      <p>
        O usuário é responsável por manter suas credenciais (e-mail e senha)
        seguras e confidenciais.
      </p>
      <p>
        Qualquer atividade realizada em sua conta será considerada de sua
        responsabilidade.
      </p>
      <p>
        Em caso de perda ou suspeita de comprometimento das credenciais, o
        usuário deve notificar a OCA BOOKS imediatamente.
      </p>
      <h3>1.3 Autorização de Notificações</h3>
      <p>
        Ao criar uma conta, o usuário autoriza a OCA BOOKS a enviar notificações
        sobre atualizações, manutenção e novidades relacionadas aos serviços.
      </p>
      <h3>1.4 Serviços em Desenvolvimento e Teste</h3>
      <p>
        A OCA BOOKS pode lancar novos recursos em fase de testes, que podem
        apresentar instabilidades ou interrupções temporárias.
      </p>
      <p>
        Sempre que possível, essas condições serão comunicadas aos usuários.
      </p>
      <h2>2. Uso da Plataforma e Restrições</h2>
      <h3>2.1 Limitação de Uso e Licenciamento</h3>
      <ul>
        <li>
          É proibido copiar, distribuir ou reproduzir conteúdos da OCA BOOKS sem
          autorização prévia.
        </li>
        <li>
          Dados fornecidos pelo usuário são utilizados apenas para fins
          operacionais e de aprimoramento da plataforma.
        </li>
      </ul>
      <h3>2.2 Uso Indevido e Penalidades</h3>
      <p>
        Qualquer tentativa de violação de sistemas, engenharia reversa ou uso de
        APIs sem autorização formal será tratada como violação dos Termos de Uso
        e poderá acarretar suspensão da conta e sanções legais.
      </p>
      <h3>2.3 Direitos de Compartilhamento de Links</h3>
      <p>
        Organizações governamentais, mecanismos de busca e veículos de notícias
        podem criar links para o site da OCA BOOKS, desde que:
      </p>
      <ul>
        <li>O link não seja enganoso.</li>
        <li>Não implique falsamente um patrocínio ou endosso da OCA BOOKS.</li>
        <li>Esteja em conformidade com o contexto do site de origem.</li>
      </ul>
      <p>
        Links que prejudiquem a imagem da OCA BOOKS ou violem boas práticas
        serão removidos.
      </p>
      <h2>3. Conteúdo e Responsabilidade do Usuário</h2>
      <h3>3.1 Publicação de Conteúdo</h3>
      <p>
        O usuário é o único responsável por todo conteúdo criado ou
        compartilhado na plataforma, incluindo textos, imagens e outras mídias.
      </p>
      <p>
        A OCA BOOKS não se responsabiliza por conteúdo publicado por terceiros,
        mas reserva-se o direito de remover materiais inapropriados ou ilegais.
      </p>
      <h3>3.2 Licenciamento de Conteúdo para Melhorias</h3>
      <p>
        O usuário autoriza a OCA BOOKS a utilizar feedbacks ou sugestões para
        aprimorar os serviços. Isso não inclui a divulgação pública de
        informações pessoais ou sensíveis.
      </p>
      <h2>4. Assinaturas, Pagamentos e Política de Cancelamento</h2>
      <h3>4.1 Planos de Assinatura e Pagamento</h3>
      <ul>
        <li>Plano Mensal: Cobrança recorrente no cartão de crédito.</li>
        <li>
          Plano Anual: Permite pagamento parcelado, sendo que o valor total é
          considerado como uma única transação.
        </li>
      </ul>
      <h3>4.2 Cancelamento e Reembolsos</h3>
      <p>
        Cancelamentos podem ser realizados a qualquer momento. No entanto, para
        planos anuais, as parcelas restantes continuarão sendo cobradas até o
        término do contrato.
      </p>
      <p>
        Não haverá reembolsos de valores já pagos, exceto em casos de
        interrupção comprovada dos serviços por parte da OCA BOOKS.
      </p>
      <h3>4.3 Alterações nos Preços</h3>
      <p>
        A OCA BOOKS pode revisar os preços de seus planos. Alterações serão
        comunicadas previamente, permitindo que os usuários decidam se desejam
        continuar utilizando os serviços sob as novas condições.
      </p>
      <h2>5. Privacidade e Segurança dos Dados</h2>
      <h3>5.1 Proteção de Dados</h3>
      <p>
        A OCA BOOKS utiliza medidas avançadas de segurança para proteger as
        informações dos usuários, como criptografia de dados e autenticação em
        dois fatores.
      </p>
      <h3>5.2 Uso de Cookies</h3>
      <p>
        Cookies são utilizados para personalizar a experiência dos usuários. As
        configurações podem ser ajustadas no navegador.
      </p>
      <h3>5.3 Exclusão de Dados Após Cancelamento</h3>
      <p>
        Após o cancelamento dos serviços, o usuário pode solicitar a exclusão de
        seus dados pessoais. A OCA BOOKS reterá informações apenas pelo período
        necessário para cumprir com obrigações legais.
      </p>
      <h2>6. Manutenção, Garantias e Limitações de Responsabilidade</h2>
      <h3>6.1 Manutenção e Atualizações</h3>
      <p>
        A OCA BOOKS pode realizar manutenções ou implementar atualizações na
        plataforma, o que pode ocasionar interrupções temporárias.
      </p>
      <p>
        Sempre que possível, essas situações serão comunicadas aos usuários.
      </p>
      <h3>6.2 Garantias Limitadas</h3>
      <p>
        Apesar de nossos esforços para ofrecer serviços de alta qualidade, não
        garantimos que a plataforma estará livre de falhas ou interrupções.
      </p>
      <p>
        Nossa responsabilidade limita-se aos valores pagos pelo usuário nos
        últimos 12 meses.
      </p>
      <h2>7. Disposições Gerais</h2>
      <h3>7.1 Modificações nos Termos de Uso</h3>
      <p>
        A OCA BOOKS pode atualizar estes Termos a qualquer momento. O uso
        contínuo da plataforma após as alterações indica aceitação dos novos
        termos.
      </p>
      <h3>7.2 Resolução de Conflitos</h3>
      <p>
        Qualquer disputa relacionada a estes Termos será resolvida na cidade de
        São Paulo, SP, sob a jurisdição das leis brasileiras.
      </p>
      <h3>7.3 Força Maior</h3>
      <p>
        A OCA BOOKS não será responsabilizada por falhas ou atrasos decorrentes
        de eventos fora de seu controle, como desastres naturais, pandemias ou
        decisões governamentais.
      </p>
      <h2>8. Contato</h2>
      <p>
        Para dúvidas ou solicitações relacionadas a estes Termos de Uso, entre
        em contato com nossa equipe pelo e-mail: suporte@ocabooks.com.
      </p>
    </Flex>
  );
}
