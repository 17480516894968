import { Box, Flex, Text } from "@chakra-ui/react";
import { formatCurrency } from "../../../../utils/utils";
import { OrderBook } from "../../../../dtos/UsersDTO";

type Props = {
  orderBook: OrderBook;
  onClick?: () => void;
  isSelected?: boolean;
  title?: string;
};

export function CardSubscription({
  orderBook,
  onClick,
  isSelected = false,
  title = "Disponível para uso",
}: Props) {
  if (orderBook && orderBook.order.plan === null) {
    return <Flex />;
  }

  return (
    <Flex
      width={["100%", "100%", "248px"]}
      minHeight="301px"
      borderRadius="15px"
      borderWidth="1px"
      borderColor={isSelected ? "green.500" : "#36A9E1"}
      px="25px"
      py="22px"
      flexDirection="column"
    >
      <Flex flexDirection="column" flex={1}>
        {orderBook && orderBook.order.plan != null && (
          <Text color="#A1A1A1" fontSize="12px" fontWeight={600}>
            {orderBook.order.plan.name}
          </Text>
        )}
        {orderBook.order.plan.category &&
          orderBook.order.plan.category === "pro" && (
            <Text color="#383838" fontSize="18px" fontWeight={700}>
              R$ {formatCurrency(orderBook.order.amount_total / 100)}
            </Text>
          )}
        {!orderBook.order.plan.category && (
          <Text color="#383838" fontSize="18px" fontWeight={700}>
            R$ {formatCurrency(orderBook.order.amount_total / 100)}
          </Text>
        )}
        {orderBook.order.plan.category &&
          orderBook.order.plan.category !== "pro" && (
            <Text color="#383838" fontSize="18px" fontWeight={700}>
              {orderBook.order.plan.category === "free" ? "Free" : "Bônus"}
            </Text>
          )}
        <Box
          width="100%"
          height="1.5px"
          mt="5px"
          mb="7px"
          backgroundColor="#D8D8D8"
        />

        <Text color="#3A3A3A" mt="10px" fontSize="15px" fontWeight={400}>
          {orderBook.order.plan?.quantity_pages} páginas
        </Text>
        <Text color="#3A3A3A" mt="10px" fontSize="15px" fontWeight={400}>
          {orderBook.order.plan?.quantity_copies_books} Cópias Impressas
        </Text>
        <Text color="#3A3A3A" mt="10px" fontSize="15px" fontWeight={400}>
          Formato de Ebook (Ilimitado)
        </Text>
      </Flex>

      <Flex
        bg={isSelected ? "green.500" : "blue.400"}
        color="white"
        style={{
          borderRadius: 50,
          height: 32,
          fontSize: 15,
          fontWeight: 600,
        }}
        _hover={{
          bg: "blue.400",
        }}
        mt="6"
        alignItems="center"
        justifyContent="center"
        cursor={onClick ? "pointer" : "none"}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {orderBook.status === "created" ? title : "Em uso"}
      </Flex>
    </Flex>
  );
}
