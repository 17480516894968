import { Button, Flex, Text } from "@chakra-ui/react";
import { useAuth } from "../../../../../hooks/useAuth";
import { CardSubscription } from "../../../../subscription/components/cardSubscription";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  onClickPlan: (id: string) => void;
};
export function SelectPlanPage({ onClickPlan }: Props) {
  const navigate = useNavigate();

  const { user } = useAuth();

  const [selectedPlanBookId, setSelectedPlanBookId] = useState("");

  return (
    <Flex width="100%" min-height="400px">
      <Flex
        gap="20px"
        mt={["20px", "20px", "0px"]}
        flexDirection={["column", "column", "row"]}
      >
        {user.subscription &&
        user.subscription.order_book &&
        user.subscription.order_book.length > 0 &&
        user.subscription.order_book.filter((item) => item.status === "created")
          .length > 0 ? (
          user.subscription.order_book
            .filter((item) => item.status === "created")
            .map((item, _) => (
              <Flex width={["100%", "100%", "248px"]} height="301px">
                <CardSubscription
                  title="Atribuir plano ao livro"
                  key={item.id}
                  orderBook={item}
                  isSelected={item.id === selectedPlanBookId}
                  onClick={() => {
                    setSelectedPlanBookId(item.id);
                    onClickPlan(item.id);
                  }}
                />
              </Flex>
            ))
        ) : (
          <Flex width="100%" height="200px" flexDirection="column">
            <Text className="text-lg font-semibold">
              Para continuar é necessário contratar um plana
            </Text>
            <Text className="mt-2 text-sm"></Text>
            <Button
              mt="10px"
              width="100%"
              bg="blue.400"
              color="white"
              _hover={{
                bg: "blue.400",
              }}
              onClick={() => {
                navigate("/planos");
              }}
            >
              Contratar plano
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
