import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BiographySelectBox } from "../selectBox";
import { ModalListBook } from "../../../../components/ModalListBook";
import { useEffect, useState } from "react";
import { useListBooks } from "../../hooks/useListBooks";
import { useNavigate } from "react-router-dom";
import { ModalNewBook } from "../../../../components/ModalNewBook";
import { MenuOption } from "../menuOption";
import { IconMenuOptionBiography } from "../iconMenuOption/iconMenuOptionBiography";
import { IconMenuOptionData } from "../iconMenuOption/iconMenuOptionData";
import { IconMenuOptionBook } from "../iconMenuOption/iconMenuOptionBook";
import { useStatusMessageBot } from "../../chatbot/hooks/useStatusMessageBot";
import { ModalPageLoadingAiNext } from "../../../../components/modalPageLoadingAiNext";
import { useBiographyContext } from "../../../../hooks/contexts/biography/biography.context";
import { X } from "lucide-react";
import { linksMenu } from "../../../../components/header";
import { RiFlashlightFill, RiLogoutBoxLine } from "react-icons/ri";
import { useAuth } from "../../../../hooks/useAuth";
import { ButtonStatusCredits } from "../../../../components/ButtonStatusCredits";
import { ButtonStatusSubscription } from "../../../../components/ButtonStatusSubscription";

type Props = {
  id: string;
  type: "biography" | "data" | "book";
};

export function BiographySidebar({ id, type }: Props) {
  const { isOpenDraw, onCloseDraw } = useBiographyContext();
  const { logout, user } = useAuth();

  const navigate = useNavigate();
  const { dataList, loading, refetch } = useListBooks();
  const { refetch: refetchBot, loading: loadingBot } = useStatusMessageBot(id);

  const [titleAlert, setTitleAlert] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenNew, setIsOpenNew] = useState(false);

  async function isCompleteChatBot() {
    return await refetchBot(id);
  }

  useEffect(() => {
    if (id) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <ModalPageLoadingAiNext
        is_open={alertOpen}
        title={titleAlert}
        statusModal="alert"
        titleBtn="Ok"
        onRefresh={() => {
          setAlertOpen(false);
        }}
      />
      <ModalNewBook
        is_open={isOpenNew}
        onClose={() => {
          if (id !== "empty") {
            setIsOpenNew(false);
          }
        }}
        onCloseSend={() => {
          setIsOpenNew(false);
        }}
      />

      {!loading && dataList.length > 0 && (
        <ModalListBook
          data={dataList}
          is_open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          onSelected={({ id }) => {
            setIsOpen(false);
            navigate(`/biografia/chatboot/${id}`);
          }}
        />
      )}

      {/* <IconButton
        top="48px"
        aria-label="open menu"
        onClick={openDraw}
        display={["flex", "flex", "none"]}
        position="absolute"
        variant="outline"
        icon={<PanelRightClose />}
      /> */}

      <Drawer placement={"left"} onClose={onCloseDraw} isOpen={isOpenDraw}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px" borderBottomColor="#fbfbfb">
            <IconButton
              mt="10px"
              aria-label="close menu"
              onClick={onCloseDraw}
              display={["flex", "flex", "none"]}
              variant="outline"
              icon={<X />}
            />
          </DrawerHeader>
          <DrawerBody>
            <Flex height="100%">
              {!loading && dataList.length > 0 && (
                <Flex
                  flexDirection="column"
                  px="20px"
                  py="20px"
                  justifyItems="flex-end"
                  alignItems="flex-end"
                  width="100%"
                >
                  <BiographySelectBox
                    title={dataList.find((item) => item.id === id)?.title ?? ""}
                    onPress={() => {
                      setIsOpen(true);
                    }}
                    onPressNewBook={() => {
                      setIsOpenNew(true);
                    }}
                  />
                  <Flex
                    width="250px"
                    flexDirection="column"
                    mt="20px"
                    gap="20px"
                  >
                    <MenuOption
                      icon={
                        <Icon
                          as={IconMenuOptionBiography}
                          fontSize="18px"
                          color="blue.400"
                        />
                      }
                      title="Meu Biógrafo"
                      isActive={type === "biography"}
                      onPress={() => {
                        navigate(`/biografia/chatboot/${id}`);
                        onCloseDraw();
                      }}
                    />

                    <MenuOption
                      icon={
                        <Icon
                          as={IconMenuOptionData}
                          fontSize="18px"
                          color="blue.400"
                        />
                      }
                      title="Base de Conhecimento"
                      isActive={type === "data"}
                      onPress={async () => {
                        onCloseDraw();
                        if (await isCompleteChatBot()) {
                          navigate(
                            `/biografia/base-conhecimento/${id}?type=infancy`
                          );
                        } else {
                          alert("Finalize o Meu Biógrafo");
                        }
                      }}
                    />

                    <MenuOption
                      icon={
                        <Icon
                          as={IconMenuOptionBook}
                          fontSize="18px"
                          color="blue.400"
                        />
                      }
                      title="Meu Livro"
                      isActive={type === "book"}
                      onPress={async () => {
                        onCloseDraw();
                        if (await isCompleteChatBot()) {
                          navigate(`/biografia/meu-livro/${id}?type=cover`);
                        } else {
                          alert("Finalize o Meu Biógrafo");
                        }
                      }}
                    />
                  </Flex>
                  <Flex
                    borderTopWidth="1px"
                    mt="15px"
                    paddingTop="15px"
                    borderTopColor="#DFDFDF"
                    flexDirection="column"
                    width="100%"
                    gap="15px"
                  >
                    {linksMenu
                      .filter((data) => data.type.includes(user.type))
                      .map((item, index) => (
                        <Link key={index} href={item.link}>
                          <Text
                            style={{
                              color: "black",
                              fontFamily: "Kanit",
                            }}
                          >
                            {item.title}
                          </Text>
                        </Link>
                      ))}

                    <Flex
                      style={{ cursor: "pointer" }}
                      alignItems="center"
                      onClick={() => {
                        logout();
                      }}
                    >
                      <Icon
                        fontSize="16px"
                        as={RiLogoutBoxLine}
                        color="blue.400"
                      />
                      <Text
                        style={{
                          color: "blue.400",
                          fontWeight: 500,
                          marginLeft: 2,
                          fontFamily: "Kanit",
                        }}
                      >
                        Sair
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    borderTopWidth="1px"
                    mt="15px"
                    paddingTop="15px"
                    borderTopColor="#DFDFDF"
                    flexDirection="column"
                    width="100%"
                    gap="15px"
                  >
                    <Flex
                      alignItems="center"
                      justifyItems="center"
                      display={["flex", "flex", "none"]}
                      gap={5}
                      flexDirection="column"
                      mt="20px"
                    >
                      <ButtonStatusCredits title="Páginas:" subtitle={"50"} />
                      <ButtonStatusSubscription
                        title="Contratar Planos"
                        icon={
                          <Icon
                            fontSize="18px"
                            as={RiFlashlightFill}
                            color="white"
                          />
                        }
                        link="/planos"
                      />
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Flex
        display={["none", "none", "flex"]}
        width="280px"
        minHeight="100vh"
        height="100%"
        borderRightWidth={1}
        borderRightColor="#EBEBEB"
      >
        {(loading || loadingBot) && (
          <Flex>
            <Stack width="280px" mt="80px" height="200px">
              <Skeleton height="10px" borderRadius="5px" width="120px" />
              <Skeleton height="40px" borderRadius="20px" width="210px" />
              <Skeleton
                height="20px"
                mt="10px"
                borderRadius="5px"
                width="180px"
              />
              <Skeleton
                height="20px"
                borderRadius="10px"
                mt="20px"
                width="210px"
              />
              <Skeleton
                height="20px"
                borderRadius="10px"
                mt="20px"
                width="210px"
              />
              <Skeleton
                height="20px"
                borderRadius="10px"
                mt="20px"
                width="210px"
              />
            </Stack>
          </Flex>
        )}

        {!loading && !loadingBot && dataList.length > 0 && (
          <Flex
            flexDirection="column"
            px="20px"
            py="60px"
            justifyItems="flex-end"
            alignItems="flex-end"
            width="100%"
          >
            <BiographySelectBox
              title={dataList.find((item) => item.id === id)?.title ?? ""}
              onPress={() => {
                setIsOpen(true);
              }}
              onPressNewBook={() => {
                setIsOpenNew(true);
              }}
            />
            <Flex width="250px" flexDirection="column" mt="20px" gap="20px">
              <MenuOption
                icon={
                  <Icon
                    as={IconMenuOptionBiography}
                    fontSize="18px"
                    color="blue.400"
                  />
                }
                title="Meu Biógrafo"
                isActive={type === "biography"}
                onPress={() => {
                  navigate(`/biografia/chatboot/${id}`);
                }}
              />

              <MenuOption
                icon={
                  <Icon
                    as={IconMenuOptionData}
                    fontSize="18px"
                    color="blue.400"
                  />
                }
                title="Base de Conhecimento"
                isActive={type === "data"}
                onPress={async () => {
                  if (await isCompleteChatBot()) {
                    navigate(`/biografia/base-conhecimento/${id}?type=infancy`);
                  } else {
                    setTitleAlert(
                      "Para acessar a base de conhecimento é necessário finalizar o 'Meu Biógrafo'"
                    );
                    setAlertOpen(true);
                  }
                }}
              />

              <MenuOption
                icon={
                  <Icon
                    as={IconMenuOptionBook}
                    fontSize="18px"
                    color="blue.400"
                  />
                }
                title="Meu Livro"
                isActive={type === "book"}
                onPress={async () => {
                  if (await isCompleteChatBot()) {
                    navigate(`/biografia/meu-livro/${id}?type=cover`);
                  } else {
                    setTitleAlert(
                      "Para acessar a sessão do Meu livro é necessário finalizar a 'Base de Conhecimento' e/ou 'Meu Biógrafo'"
                    );
                    setAlertOpen(true);
                  }
                }}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
}
